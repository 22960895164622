<template>
    <div class="orderIndexView content-index">
        <div class="topView">
            <div class="tView">
                <el-page-header
                        style="width: 100%;padding:10px"
                        @back="$router.go(-1)"
                        content="衣物返洗"
                ></el-page-header>
            </div>
            <el-button type="primary" class="orderBtn" @click="reWashRecord"
            >返洗记录
            </el-button
            >
        </div>
        <div class="filterView">
            <div class="keywordView">
                <input
                        placeholder="请输入条码号"
                        v-model.trim="clothesNum"
                        @keyup.enter="handleClothes"
                />
                <button
                        @click="handleClothes"
                        :class="{ 'btn-search': clothesNum !== '' }"
                >
                    查询
                </button>
            </div>
        </div>
        <div class="contentView">
            <Table
                    :button-type="true"
                    :header-style="headerStyle"
                    :height="null"
                    :orientation="'center'"
                    :serial-number="false"
                    :page="true"
                    :table-data="tableData"
                    :title-list="titleListData"
                    @operationClick="operationClick"
            />
        </div>
        <pop-view
                id="refundViewPopView"
                :show="dialogTableVisible"
                width="600px"
                top="15%"
                title="返洗操作确认"
                @cancel="close"
        >
            <div style="display: table;margin: 0 auto; font-size: 15px">
                <div
                        style="margin: 20px 0; display: flex;align-items: center;text-align: center;"
                >
                    <span>返洗类型：</span>
                    <el-select
                            style="width: 280px;"
                            v-model="rewashType"
                            placeholder="请选择返洗类型"
                            @change="changeRewashType"
                    >
                        <el-option
                                v-for="item in storeBackwashType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div style="display: flex;align-items: center;text-align: center;">
                    <span>返洗原因：</span>
                    <el-select
                            style="width: 280px;"
                            v-model="reWashReason"
                            placeholder="请选择返洗原因"
                    >
                        <el-option
                                v-for="item in reWashReasonOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div
                        style="margin: 20px 0;display: flex;align-items: center;text-align: center;"
                >
                    <span>返洗说明：</span>
                    <el-input
                            type="textarea"
                            placeholder="请输入返洗说明"
                            v-model.trim="reWashDesc"
                            maxlength="20"
                            style="width: 280px;"
                            show-word-limit
                    ></el-input>
                </div>
                <div style="margin: 20px 0;">
                    <div style="margin-bottom:5px">返洗拍照:</div>
                    <div style="color:#940101;font-size:12px">
                        *为了工厂能够准确处理返洗衣物，请对需要返洗的部位进行拍照
                    </div>
                    <el-button
                            plain
                            size="small"
                            style="margin:10px 0"
                            @click="photoFGraph"
                    >我要拍照
                    </el-button
                    >
                    <div class="image-box">
                        <div
                                class="image-item"
                                v-for="(item, index) in imgarr"
                                :key="index"
                        >
                            <el-image
                                    :src="item"
                                    style="width: 50px;height:50px"
                                    fit="fill"
                                    :preview-src-list="imgarr"
                            ></el-image>
                            <span
                                    @click="toDelete(item)"
                                    class="el-icon-close close-button"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn" style="text-align: center;margin-bottom: 15px">
                <el-button @click="handleAction('cancel')">取消</el-button>
                <el-button
                        style="margin-left: 10px"
                        type="primary"
                        :loading="reWashLoading"
                        @click="handleAction('yes')"
                >确认
                </el-button
                >
            </div>
        </pop-view>

        <transition name="userDetails">
            <div class="sendWashRecordView" v-if="recordDialogTableVisible">
                <div class="topView">
                    <div class="backView">
                        <el-page-header
                                @back="recordDialogTableVisible = false"
                                content="返洗记录"
                        ></el-page-header>
                    </div>
                </div>
                <div class="contentView">
                    <el-table
                            style="width: 100%;"
                            ref="selectedStaffTable"
                            height="559px"
                            v-loading="reWashRecordDetailLoading"
                            :data="reWashRecordList"
                            border
                    >
                        <el-table-column
                                prop="id"
                                align="center"
                                label="返洗单号"
                                width="100"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="orderNumber"
                                align="center"
                                label="订单号"
                                min-width="150"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="clothesNum"
                                align="center"
                                min-width="180"
                                label="衣物号"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="outClothesNum"
                                align="center"
                                min-width="120"
                                label="外部衣物号"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="clothesName"
                                align="center"
                                label="衣物名称"
                                :show-overflow-tooltip="true"
                                min-width="150"
                        ></el-table-column>
                        <el-table-column
                                prop="rewashTypeStr"
                                align="center"
                                label="返洗类型"
                                min-width="100"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="rewashFlagStr"
                                align="center"
                                label="返洗标识"
                                min-width="100"
                                :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                                prop="reason"
                                align="center"
                                label="返洗原因"
                                :show-overflow-tooltip="true"
                                min-width="150"
                        ></el-table-column>
                        <el-table-column
                                prop="rewashDesc"
                                align="center"
                                :show-overflow-tooltip="true"
                                label="返洗说明"
                                min-width="150"
                        ></el-table-column>
                        <el-table-column
                                prop="createTime"
                                align="center"
                                :show-overflow-tooltip="true"
                                min-width="180"
                                label="返洗时间"
                        ></el-table-column>
                        <el-table-column
                                prop="storeUserName"
                                align="center"
                                min-width="100"
                                :show-overflow-tooltip="true"
                                label="操作人"
                        ></el-table-column>
                        <el-table-column align="center" label="操作" width="160">
                            <template slot-scope="scope">
                                <el-button
                                        type="primary"
                                        size="mini"
                                        @click="printLabel(scope.row)"
                                >打印
                                </el-button
                                >
                                <el-button type="danger" size="mini" @click="revoke(scope.row)"
                                >撤销
                                </el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 10px;">
                        <el-pagination
                                background
                                style="text-align:right"
                                layout="prev, pager, next"
                                :total="total"
                                @current-change="handleCurrentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </transition>
        <pop-view
                id="refundViewPopView"
                :show="backWashVisible"
                width="600px"
                top="15%"
                title="返洗撤销"
                @cancel="closeBackWash"
        >
            <div style="display: table;margin: 0 auto; font-size: 15px">
                <div style="margin: 20px 0; display: flex;">
                    <span style="width:110px;text-align:right">撤销衣物号：</span>
                    <span>{{ rowData.clothesNum }}</span>
                </div>
                <div style="margin: 20px 0; display: flex;">
                    <span style="width:110px;text-align:right">撤销衣物名称：</span>
                    <span>{{ rowData.clothesName }}</span>
                </div>
                <div style="margin: 20px 0;display: flex;">
                    <span style="width:110px;text-align:right">撤销原因：</span>
                    <el-input
                            type="textarea"
                            placeholder="请输入撤销原因"
                            v-model.trim="reason"
                            maxlength="50"
                            style="width: 200px"
                            show-word-limit
                    ></el-input>
                </div>
            </div>
            <div
                    class="btn"
                    style="text-align: center; margin-top: 15px;margin-bottom: 15px"
            >
                <el-button @click="closeBackWash">取消</el-button>
                <el-button
                        style="margin-left: 10px"
                        type="primary"
                        :loading="buttonLoading"
                        @click="enterBackWash"
                >确定撤销
                </el-button
                >
            </div>
        </pop-view>
        <el-dialog center :visible.sync="photographVisible" width="800px" top="5vh">
            <div style="height: 500px">
                <div
                        v-show="!hasAuth"
                        class="camera_auth"
                        style="width: 100%; height: 100%"
                >
                    <el-button
                            type="text"
                            @click="getCompetence"
                            style="
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            "
                    ><img
                            src="http://www.xiyitong.net/web/wash/dist/static/img/photo.117f2f5.png"
                            alt=""
                            style="
                width: 60px;
                height: 60px;
                margin-bottom: 12px;
                cursor: pointer;
              "
                    />
                        <p>打开摄像头</p></el-button
                    >
                </div>
                <div
                        v-show="hasAuth"
                        class="camera_outer"
                        style="width: 100%; height: 100%; position: relative"
                >
                    <video
                            id="videoCamera"
                            autoplay
                            style="width: 100%; height: 100%"
                    ></video>
                    <canvas
                            id="canvasCamera"
                            ref="videoCamera"
                            :width="videoWidth"
                            :height="videoHeight"
                            style="
              position: absolute;
              top: 0;
              left: 0;
              visibility: hidden;
              width: 100%;
              height: 100%;
            "
                    ></canvas>
                    <el-button :loading="imageLoading" class="shoot" @click="setImage()">
                        拍照
                    </el-button>
                    <el-button @click="stopNavigator">关闭摄像头</el-button>
                </div>
            </div>
            <div class="swiper-out" @mouseover="mouseover" @mouseleave="mouseleave">
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(item, index) in imgSrc" :key="index">
                        <div class="swiper-item">
                            <div style="width: 100px; position: relative">
                                <el-image
                                        :src="item.url"
                                        style="width: 100px;height:100px"
                                        fit="fill"
                                        :preview-src-list="imgarr"
                                ></el-image>
                                <el-button
                                        type="danger"
                                        size="mini"
                                        style="position: absolute; top: 0px; right: 0px"
                                        icon="el-icon-delete"
                                        circle
                                        @click="toDelete(item.url)"
                                ></el-button>
                                <div>图片{{ index + 1 }}</div>
                            </div>
                        </div>
                    </swiper-slide>
                    <div
                            class="left-icon"
                            v-show="imgSrc.length > 5 && iconShow"
                            slot="button-prev"
                    >
                        <i class="el-icon-d-arrow-left"></i>
                    </div>
                    <div
                            class="right-icon"
                            v-show="imgSrc.length > 5 && iconShow"
                            slot="button-next"
                    >
                        <i class="el-icon-d-arrow-right"></i>
                    </div>
                </swiper>
            </div>
            <el-button
                    size="medium"
                    class="enter-button"
                    type="primary"
                    @click="enterPhotograph"
            >
                确 认
            </el-button>
        </el-dialog>
    </div>
</template>

<script>
import {
    rewashRecord,
    getClothesByClothesNumForReWash,
    clothesReWash,
    rewashRevoke,
    getStoreRewashReason,
} from "@/api/clothes/clothes";
import {queryDictItemByDictName} from "@/api/todayData";
import PopView from "@/components/PopView";
import {getLodop} from "@/print/LodopFuncs";
import Table from "@/components/table2";
import {client} from "@/utils/alioss";
import {getAliYunSts} from "@/api/file/ali";
import "swiper/dist/css/swiper.css";
import OSS from "ali-oss";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import moment from "moment";

export default {
    name: "index",
    components: {
        PopView,
        Table,
        swiper,
        swiperSlide,
    },
    data() {
        return {
            showModal: false,
            dialogTableVisible: false,
            recordDialogTableVisible: false,
            reWashRecordDetailLoading: false,
            headerStyle: {
                fontSize: "14px",
                background: "#f5f7fa",
                color: "#363636",
            },
            tableData: [],
            titleListData: [
                {
                    prop: "clothesNum",
                    label: "衣物号",
                },
                {
                    prop: "outClothesNum",
                    label: "外部衣物号",
                },
                {
                    prop: "clothesName",
                    label: "衣物名称",
                },
                {
                    prop: "colorName",
                    label: "衣物颜色",
                },
                {
                    prop: "clothesStatus",
                    label: "衣物状态",
                },
            ],
            choose: {},
            clothesNum: "",
            queryLoading: false,
            reWashLoading: false,
            reWashReason: "",
            rewashType: "",
            reWashDesc: "",
            printIndex: 0,
            reWashRecordList: [],
            reWashReasonOptions: [],

            value: "",
            total: 0,
            page: 1,
            size: 10,
            backWashVisible: false,
            reason: "",
            rowData: {},
            buttonLoading: false,
            photographVisible: false,
            swiperOption: {
                loop: true, // 循环模式选项
                autoplay: false, //自动循环
                slidesPerView: 5, //设置slider容器能够同时显示的slides数量(carousel模式)。
                spaceBetween: 5, //在slide之间设置距离（单位px）。
                centeredSlides: false, //设置活动块居中
                navigation: {
                    nextEl: ".right-icon",
                    prevEl: ".left-icon",
                },
            },
            hasAuth: false,
            videoWidth: 0,
            videoHeight: 0,
            imageLoading: false,
            aliSts: {},
            thisCanvas: null,
            thisContext: null,
            thisVideo: null,
            iconShow: false,
            imgarr: [],
            arrLength: 0,
            fileList: [],
            imgSrc: [],
            storeBackwashType: [],
        };
    },
    watch: {
        photographVisible(value) {
            if (value == false) {
                this.stopNavigator();
                this.imageLoading = false;
            }
        },
    },
    computed: {
        scrollerHeight: function () {
            return window.innerHeight - 200 + "px"; //自定义高度需求
        },
    },
    methods: {
        // 获取门店返洗类型
        getStoreBackwashTypes() {
            queryDictItemByDictName("门店返洗类型").then((response) => {
                this.storeBackwashType = response.data.data.map((item) => {
                    return {
                        value: item.itemValue,
                        label: item.itemText,
                    };
                });
            });
        },
        // 获取门店返洗原因
        getStoreRewashReasonList(rewashType) {
            getStoreRewashReason({rewashType: rewashType}).then(({data}) => {
                if (data.code == 0) {
                    this.reWashReasonOptions = data.data.map((item) => {
                        return {
                            value: item.reason,
                            label: item.reason,
                        };
                    });
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        changeRewashType() {
            this.reWashReason = null;
            this.getStoreRewashReasonList(this.rewashType);
        },
        handleClothes() {
            this.tableData = [];
            this.queryLoading = true;
            if (!this.clothesNum) {
                this.queryLoading = false;
                this.$message.error("请输入条码号");
                return;
            }
            let aa = this.tableData.find((c) => c.clothesNum === this.clothesNum);
            if (aa) {
                this.queryLoading = false;
                this.$message.error("已经扫描过了");
                return false;
            }
            getClothesByClothesNumForReWash(this.clothesNum, 0).then((response) => {
                this.queryLoading = false;
                if (response.data.code === 0) {
                    let haha = response.data.data;
                    if (haha.length === 0) {
                        this.$message.error("此衣物不能返洗");
                    }
                    for (let i = 0; i < haha.length; i++) {
                        haha[i].buttonList = [
                            {
                                name: "返洗",
                                size: "mini",
                                type: "primary",
                                code: "backwash",
                            },
                        ];
                        this.tableData.push(haha[i]);
                    }
                } else {
                    this.$message.error(response.data.msg);
                }
            });
        },
        operationClick({row, code}) {
            if (code == "backwash") {
                this.getStoreBackwashTypes();
                this.dialogTableVisible = true;
                this.reWashReason = "";
                this.reWashDesc = "";
                this.rewashType = "";
                this.imgarr = [];
                this.arrLength = 0;
                this.fileList = [];
                this.imgSrc = [];
                this.choose = {...row};
            }
        },

        handleAction(action) {
            if (action === "yes") {
                if (!this.reWashReason) {
                    this.$message.error("请输入返洗原因");
                    return false;
                }
                if (!this.reWashDesc) {
                    this.$message.error("请输入返洗说明");
                    return false;
                }
                if (!this.imgarr || this.imgarr.length == 0) {
                    this.$message.error("返洗照片不能为空");
                    return false;
                }
                // 确认
                this.handleReWash();
            } else {
                this.dialogTableVisible = false;
                this.close();
            }
        },
        // 确定返洗
        handleReWash() {
            this.reWashLoading = true;
            let item = this.choose;
            let data = {
                id: item.id, // 衣物主键Id
                orderNumber: item.orderNumber,
                clothesNumber: item.clothesNum,
                clothesName: item.clothesName,
                clothesStatus: item.clothesStatus,
                reWashReason: this.reWashReason,
                reWashDesc: this.reWashDesc,
                rewashType: this.rewashType,
                images: this.imgarr,
            };
            clothesReWash(data)
                .then((response) => {
                    if (response.data.code === 0) {
                        this.$message.success(response.data.msg);
                        this.clothesNum = "";
                        this.dialogTableVisible = false;
                        this.tableData = [];
                        this.printLabel(response.data.data);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                    this.reWashLoading = false;
                })
                .catch(() => {
                    this.reWashLoading = false;
                });
        },
        reWashRecord() {
            this.recordDialogTableVisible = true;
            this.reWashRecordDetailLoading = true;
            rewashRecord({
                size: this.size,
                current: this.page,
            }).then((res) => {
                console.log(res);
                this.reWashRecordList = res.data.data.records;
                this.total = res.data.data.total;
                this.reWashRecordDetailLoading = false;
            });
        },
        handleCurrentChange(val) {
            console.log(val);
            this.page = val;
            this.reWashRecord();
        },
        getPrint(type) {
            let defaultName;
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );
            if (type === 1) {
                //小票
                defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
            } else {
                //水洗唛
                defaultName = "TSC";
            }
            let arr = [];
            let iPrinterCount = LODOP.GET_PRINTER_COUNT();
            for (let i = 0; i < iPrinterCount; i++) {
                arr.push({
                    label: LODOP.GET_PRINTER_NAME(i),
                    value: i,
                });
            }
            arr.forEach((item) => {
                if (item.label === defaultName) {
                    this.printIndex = item.value;
                }
            });
            console.log(defaultName);
        },

        // 打印标签
        printLabel(row) {
            console.log(row, "打印返洗小票数据");
            let backwashing = "";
            if (row.rewashTypeStr == "脏污返洗") {
                backwashing = "脏\r\n污";
            } else if (row.rewashTypeStr == "修复（售后）返洗") {
                row.rewashTypeStr = "修复(售后)返洗";
                backwashing = "售\r\n后";
            } else if (row.rewashTypeStr == "煲烫返洗") {
                backwashing = "煲\r\n烫";
            } else if (row.rewashTypeStr == "寻找附件返洗") {
                backwashing = "找\r\n附\r\n件";
            }
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );
            this.getPrint(2);
            LODOP.PRINT_INIT("水洗唛"); //首先一个初始化语句
            LODOP.SET_PRINT_PAGESIZE(1, "110mm", "22mm", "");
            if (row.rewashTypeStr == "寻找附件返洗") {
                LODOP.ADD_PRINT_TEXT(10, 2, 24, 48, backwashing);
            } else {
                LODOP.ADD_PRINT_TEXT(21, 2, 24, 38, backwashing);
            }
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(6, 21, 147, 20, "门店:" + row.storeName);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(6, 151, 135, 20, "衣物:" + row.clothesName);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(6, 281, 140, 20, "衣物号:" + row.outClothesNum);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(32, 22, 140, 20, "类型:" + row.rewashTypeStr);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(32, 143, 140, 20, "原因:" + row.reason);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(
                32,
                271,
                151,
                20,
                "返洗时间:" + moment(new Date()).format("YYYY-MM-DD")
            );
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.ADD_PRINT_TEXT(56, 20, 403, 20, "描述:" + row.rewashDesc);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.SET_PRINTER_INDEX(this.printIndex);
            LODOP.PRINT();
        },
        //撤销
        revoke(data) {
            this.backWashVisible = true;
            this.rowData = data;
        },
        closeBackWash() {
            this.backWashVisible = false;
            this.reason = "";
        },
        enterBackWash() {
            if (this.reason == "") {
                this.$message.error("请输入撤销原因！");
                return;
            }
            this.buttonLoading = true;
            rewashRevoke({
                id: this.rowData.id,
                reason: this.reason,
            })
                .then(({data: res}) => {
                    if (res.code == 0) {
                        this.$message({
                            type: "success",
                            message: "撤销成功!",
                        });
                        this.reWashRecord();
                        this.closeBackWash();
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.buttonLoading = false;
                })
                .catch(() => {
                    this.buttonLoading = false;
                });
        },
        close() {
            this.dialogTableVisible = false;
            this.reWashReason = "";
            this.reWashDesc = "";
            this.imgarr = [];
            this.arrLength = 0;
            this.fileList = [];
            this.imgSrc = [];
        },
        photoFGraph() {
            if (this.fileList.length >= 10) {
                this.$message.error("最多只能上传和拍摄10张照片！");
            } else {
                this.photographVisible = true;
                this.getCompetence();
            }
        },
        // 获取阿里云oss sts
        getAliYunSts() {
            getAliYunSts().then((response) => {
                this.aliSts = response.data.data;
            });
        },
        // 调用权限（打开摄像头功能）
        getCompetence() {
            // 获取阿里云配置
            this.getAliYunSts();
            const _this = this;
            this.$nextTick(() => {
                this.thisCanvas = document.getElementById("canvasCamera");
                this.thisContext = this.thisCanvas.getContext("2d");
                this.thisVideo = document.getElementById("videoCamera");
                // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }
                // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
                // 使用getUserMedia，因为它会覆盖现有的属性。
                // 这里，如果缺少getUserMedia属性，就添加它。
                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function (constraints) {
                        // 首先获取现存的getUserMedia(如果存在)
                        var getUserMedia =
                            navigator.webkitGetUserMedia ||
                            navigator.mozGetUserMedia ||
                            navigator.getUserMedia;
                        // 有些浏览器不支持，会返回错误信息
                        // 保持接口一致
                        if (!getUserMedia) {
                            return Promise.reject(
                                new Error("getUserMedia is not implemented in this browser")
                            );
                        }
                        // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                        return new Promise(function (resolve, reject) {
                            getUserMedia.call(navigator, constraints, resolve, reject);
                        });
                    };
                }
                _this.hasAuth = true;
                setTimeout(() => {
                    _this.videoWidth = _this.$refs.videoCamera.clientWidth;
                    _this.videoHeight = _this.$refs.videoCamera.clientHeight;
                    var constraints = {
                        audio: false,
                        video: {
                            width: _this.videoWidth,
                            height: _this.videoHeight,
                            transform: "scaleX(-1)",
                        },
                    };
                    navigator.mediaDevices
                        .getUserMedia(constraints)
                        .then(function (stream) {
                            // 旧的浏览器可能没有srcObject
                            if ("srcObject" in _this.thisVideo) {
                                _this.thisVideo.srcObject = stream;
                            } else {
                                // 避免在新的浏览器中使用它，因为它正在被弃用。
                                _this.thisVideo.src = window.URL.createObjectURL(stream);
                            }
                            _this.thisVideo.onloadedmetadata = function (e) {
                                _this.thisVideo.play();
                            };
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }, 0);
            });
        },
        //  绘制图片（拍照功能）
        setImage() {
            this.imageLoading = true;
            let _this = this;
            // 点击，canvas画图
            _this.thisContext.drawImage(
                _this.thisVideo,
                0,
                0,
                _this.videoWidth,
                _this.videoHeight
            );
            // 获取图片base64链接

            let image = this.thisCanvas.toDataURL("image/png");
            // _this.imgSrc.push(image)
            if (_this.fileList.length < 10) {
                this.toUpload(image);
            } else {
                this.$message.error("最多只能上传和拍摄10张照片！");
                this.imageLoading = false;
            }
        },
        enterPhotograph() {
            this.photographVisible = false;
        },
        // 关闭摄像头
        stopNavigator() {
            if (this.thisVideo != null) {
                this.thisVideo.srcObject.getTracks()[0].stop();
                // this.thisVideo.srcObject.stop();
                this.hasAuth = false;
            }
        },
        // 上传图片
        toUpload(urlData) {
            let _this = this;
            let client = new OSS({
                region: _this.aliSts.region,
                accessKeyId: _this.aliSts.accessKeyId,
                accessKeySecret: _this.aliSts.accessKeySecret,
                stsToken: _this.aliSts.stsToken,
                bucket: _this.aliSts.bucket,
            });
            // 压缩图片，渲染页面
            _this.compressPic(urlData, "1", function (newBlob, newBase) {
                console.log(newBlob);
                const reader = new FileReader();
                reader.readAsArrayBuffer(newBlob);
                reader.onload = (event) => {
                    // arrayBuffer转Buffer
                    const buffer = new OSS.Buffer(event.target.result);
                    // 上传
                    // 随机命名
                    let random_name = "sale/" + new Date().getTime() + "." + "png";
                    client
                        .put(random_name, buffer)
                        .then((result) => {
                            _this.imageLoading = false;
                            // _this.$customAlert({message: '保存成功'})
                            if (_this.fileList.length < 10) {
                                _this.fileList.push({
                                    url: _this.aliSts.domain + "/" + random_name,
                                    name: random_name,
                                    bool: true,
                                });
                                _this.imgSrc = [];
                                _this.imgarr = [];
                                console.log(result);
                                _this.imgSrc = _this.fileList.filter((item) => {
                                    if (item.bool) {
                                        _this.imgarr.push(item.url);
                                        return item;
                                    }
                                });
                                _this.arrLength = _this.imgarr?.length;
                            }
                        })
                        .catch((err) => {
                            this.imageLoading = false;
                            console.log("错误", err);
                        });
                };
            });
        },
        // 参数: base64地址,压缩比例，回调函数(返回压缩后图片的blob和base64)
        compressPic: function (base64, scale, callback) {
            const that = this;
            let _img = new Image();
            _img.src = base64;
            _img.onload = function () {
                let _canvas = document.createElement("canvas");
                let w = this.width / scale;
                let h = this.height / scale;
                _canvas.setAttribute("width", w);
                _canvas.setAttribute("height", h);
                _canvas.getContext("2d").drawImage(this, 0, 0, w, h);
                _canvas.getContext("2d").fillStyle = "red"; // 水印颜色，透明度
                _canvas.getContext("2d").font = "24px 宋体"; // 文字大小
                _canvas.getContext("2d").textAlign = "right";
                _canvas
                    .getContext("2d")
                    .fillText(
                        "返洗时间：" + moment(new Date()).format("YYYY-MM-DD HH:mm"),
                        w - 20,
                        h - 20
                    );
                let base64 = _canvas.toDataURL("image/jpeg");
                // 当canvas对象的原型中没有toBlob方法的时候，手动添加该方法
                if (!HTMLCanvasElement.prototype.toBlob) {
                    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
                        value: function (callback, type, quality) {
                            let binStr = atob(this.toDataURL(type, quality).split(",")[1]),
                                len = binStr.length,
                                arr = new Uint8Array(len);
                            for (let i = 0; i < len; i++) {
                                arr[i] = binStr.charCodeAt(i);
                            }
                            callback(new Blob([arr], {type: type || "image/png"}));
                        },
                    });
                } else {
                    _canvas.toBlob(function (blob) {
                        if (blob.size > 1024 * 1024) {
                            that.compressPic(base64, scale, callback);
                        } else {
                            callback(blob, base64);
                        }
                    }, "image/jpeg");
                }
            };
        },
        // 删除图片
        toDelete(fileName) {
            this.$confirm("确认删除图片, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let _this = this;
                    let client = new OSS({
                        region: this.aliSts.region,
                        accessKeyId: this.aliSts.accessKeyId,
                        accessKeySecret: this.aliSts.accessKeySecret,
                        stsToken: this.aliSts.stsToken,
                        bucket: this.aliSts.bucket,
                    });
                    console.log(client, fileName);
                    let fileName1 = fileName.substring(
                        fileName.indexOf(this.aliSts.domain) + this.aliSts.domain.length + 1
                    );
                    client
                        .delete(fileName1)
                        .then((result) => {
                            this.$message.success("删除成功");
                            _this.fileList = _this.fileList.filter(
                                (item) => item.url != fileName
                            );
                            _this.imgSrc = _this.imgSrc.filter(
                                (item) => item.url != fileName
                            );
                            _this.imgSrc = [];
                            _this.imgarr = [];
                            _this.imgSrc = _this.fileList.filter((item) => {
                                if (item.bool) {
                                    _this.imgarr.push(item.url);
                                    return item;
                                }
                            });
                            _this.arrLength = _this.imgarr?.length;
                        })
                        .catch((err) => {
                            console.log("错误", err);
                        });
                })
                .catch(() => {
                    // this.$customAlert({message: '取消删除'})
                });
        },
        mouseover() {
            this.iconShow = true;
        },
        mouseleave() {
            this.iconShow = false;
        },
    },
};
</script>

<style scoped lang="scss">
.el-button--default {
  border: 1px solid #dcdfe6;
}

.orderIndexView {
  position: relative;
  padding: 0 24px 0 20px;
  overflow: auto;

  .topView {
    position: relative;
    padding: 17.5px 0;
    overflow: hidden;
    z-index: 101;
    background: #fff;

    .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      line-height: 40px;
    }

    .orderBtn {
      float: right;
    }
  }

  > .filterView {
    display: flex;
    height: 44px;

    > .keywordView {
      position: relative;
      padding-right: 90px;
      overflow: hidden;

      > input {
        width: 180px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        outline: none;
        border: none;
        background: transparent;
      }

      > button {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 44px;
        padding: 0 20px;
        line-height: 44px;
        background: #eee;
        font-size: 16px;
        font-family: PingFangSC-Medium;
        color: #fff;
      }

      > .btn-search {
        background: #3370ff;
      }
    }

    > div {
      background: #f8f8f8;
      border-radius: 3px;
    }

    > div {
      padding: 7px 12px;
      line-height: 30px;
    }
  }

  > .contentView .flexView {
    display: flex;
    padding: 15px 140px 15px 25px;

    > label {
      flex: 1;
    }

    > label:nth-child(1) {
      flex: 1.2;
    }
  }

  > .contentView {
    padding-top: 20px;

    > .headerView {
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        line-height: 20px;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 30px;
          margin: auto;
          width: 65px;
          height: 36px;
          border-radius: 6px;
          background: #3370ff;
          font-size: 14px;
          color: #fff;
        }
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }
    }
  }
}

.orderIndexView .tipView {
  color: #999;
  text-align: center;
  font-size: 13px;
  line-height: 150px;
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

input {
  box-sizing: border-box;
  border: none;
  font-size: 14px;
}

.sendWashRecordView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 200;
  padding: 60px 20px 25px 25px;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 115px;*/
    height: 50px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    /*box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);*/

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.camera_outer {
  display: flex;
  height: 400px;
  overflow: auto;
}

.shoot {
  width: 60px;
  height: 60px;
  font-size: 15px;
  background: rgba(43, 172, 45, 0.6);
  color: #fff;
  border: 0 none;
  outline: none;
  border-radius: 50%;
  margin: 0 0.2rem;
  position: absolute;
  bottom: 15px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
  }

  & + button {
    position: absolute;
    bottom: 15px;
    left: 5px;
  }
}

.swiper-slide {
  width: 20% !important;
  margin: 0 !important;
}

.swiper-out {
  padding: 20px 50px;
  height: 150px;
}

.left-icon {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-80%);
  z-index: 999;
  cursor: pointer;

  i {
    color: #fff;
    font-size: 30px;
  }
}

.right-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-80%);
  z-index: 999;
  cursor: pointer;

  i {
    color: #fff;
    font-size: 30px;
  }
}

.swiper-slide {
  text-align: center;
  height: 76%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  position: relative;
  // transform: scale(0.6);
}

.swiper-slide-next {
  // transform: scale(0.8);
  z-index: 998 !important;
}

.swiper-slide-active {
  z-index: 999 !important;
}

.enter-button {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.flaw-input {
  ::v-deep .el-input__inner {
    color: red;
  }
}

.image-box {
  display: flex;
  flex-wrap: wrap;
  width: 360px;

  .image-item {
    position: relative;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    margin-bottom: 10px;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
